import { gql, useQuery } from '@apollo/client'
import {
  PayrollSettingsQuery,
  PayrollSettingsQueryVariables,
  PostDeadline
} from '../../../gen/queries'

const SETTINGS_QUERY = gql`
  query payrollSettings {
    payrollSettings {
      payrollPostDeadline
      deadlineEnforcement
      autoApproveTimesheets
    }
  }
`

export const usePayrollSettings = (): {
  loading: boolean
  refetch: () => void
  settings: {
    postDeadline?: PostDeadline
    deadlineEnforcement: boolean
    autoApproveTimesheets: boolean
  }
} => {
  const { data, loading, refetch } = useQuery<
    PayrollSettingsQuery,
    PayrollSettingsQueryVariables
  >(SETTINGS_QUERY)

  return {
    loading,
    refetch,
    settings: {
      postDeadline: data?.payrollSettings.payrollPostDeadline,
      deadlineEnforcement: data?.payrollSettings.deadlineEnforcement === true,
      autoApproveTimesheets:
        data?.payrollSettings.autoApproveTimesheets === true
    }
  }
}
